import E from 'wangeditor';
const { $, BtnMenu } = E;

class WeChatNewsInfo extends BtnMenu {
  constructor(editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = E.$(
    `<div class="w-e-menu" data-title="微信图文内容">
              <i class="fab fa-weixin"></i>
          </div>`
    )
    super($elem, editor);
  }
  clickHandler(e) {
    // 做任何你想做的事情
    // 可参考【常用 API】文档，来操作编辑器
    let wechat = new Event('wechat');
    if(window.dispatchEvent) {
      window.dispatchEvent(wechat);
    } else {
      window.fireEvent(wechat);
    }
  }
  tryChangeActive() {
    if(this.isActive)
      this.active()
    else if ( !this.isActive )
      this.unActive();
  }
}

export default WeChatNewsInfo
